import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/BlankView.vue'),
    redirect: '/about/index',
    children: [
      {
        path: 'index',
        name: 'carpoly',
        component: () => import('@/views/about/AboutView.vue')
      }
    ]
  },
  {
    path: '/products',
    name: 'product',
    component: () => import('@/views/BlankView.vue'),
    redirect: '/products/furniture/index',
    children: [
      {
        path: 'furniture',
        name: 'furnitureCoating',
        component: () => import('@/views/BlankView.vue'),
        redirect: '/products/furniture/index',
        children: [
          {
            path: 'index',
            name: 'FurnitureCoatingIndex',
            component: () =>
              import('@/views/products/furniture/IndexView.vue')
          },
          {
            path: 'product',
            name: 'furnitureProduct',
            component: () =>
              import('@/views/products/furniture/ProductView.vue')
          },
          {
            path: 'solution',
            name: 'furnitureSolution',
            component: () =>
              import('@/views/products/furniture/SolutionView.vue')
          },
          {
            path: 'wood',
            name: 'furnitureWoodSolution',
            component: () =>
              import('@/views/products/furniture/WoodSolutionView.vue')
          },
          {
            path: 'space',
            name: 'CraftsAndSpace',
            component: () =>
              import('@/views/products/furniture/CraftsAndSpaceView.vue')
          },
          {
            path: 'crafts',
            name: 'CraftsPart',
            component: () =>
              import('@/views/products/furniture/CraftsPartView.vue')
          }
        ]
      },
      {
        path: 'architectural',
        name: 'ArchitecturalCoating',
        component: () => import('@/views/BlankView.vue'),
        redirect: '/products/architectural/index',
        children: [
          {
            path: 'index',
            name: 'ArchitecturalCoatingIndex',
            component: () =>
              import('@/views/products/architectural/IndexView.vue')
          },
          {
            path: 'product',
            name: 'ArchitecturalCoatingProduct',
            component: () =>
              import('@/views/products/architectural/ProductView.vue')
          },
          {
            path: 'solution',
            name: 'ArchitecturalCoatingSolution',
            component: () =>
              import('@/views/products/architectural/SolutionView.vue')
          },
          {
            path: 'display',
            name: 'ArchitecturalCoatingDisplay',
            component: () =>
              import('@/views/products/architectural/DisplayView.vue')
          },
          {
            path: 'case',
            name: 'ArchitecturalCoatingCase',
            component: () =>
              import('@/views/products/architectural/CaseView.vue')
          }
        ]
      },
      {
        path: 'improvement',
        name: 'HomeImprovement',
        component: () => import('@/views/BlankView.vue'),
        redirect: '/products/improvement/index',
        children: [
          {
            path: 'index',
            name: 'HomeImprovementIndex',
            component: () =>
              import('@/views/products/improvement/IndexView.vue')
          },
          {
            path: 'product',
            name: 'HomeImprovementProduct',
            component: () =>
              import('@/views/products/improvement/ProductView.vue')
          },
          {
            path: 'case',
            name: 'HomeImprovementCase',
            component: () =>
              import('@/views/products/improvement/CaseView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/products/furniture/SupplyAndLogisticsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/BlankView.vue'),
    redirect: '/news/index',
    children: [
      {
        path: 'index',
        name: 'NewsIndex',
        component: () => import('@/views/news/IndexView.vue')
      },
      {
        path: 'detail',
        name: 'NewsDetail',
        component: () => import('@/views/news/DetailView.vue')
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/SearchView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash
          })
        }, 300)
      })
    }
    // 始终滚动到顶部
    return { top: 0 }
  }
})

export default router
