<template>
  <div class="nav">
    <div class="nav-content">
      <router-link to="/">
        <img class="nav-content-logo" src="@/assets/nav/logo.png" alt="">
      </router-link>
      <ul class="nav-content-menu">
        <template v-for="item in level1Nav " :key="item.path">
          <li :class="['nav-content-menu-item', handleActiveLevel1Nav(item.path)]" @mouseover="handleMouseover(item)"
            @mouseleave="hideChildMenu" @click="hideChildMenu">
            <LinkTo :to="item.path">
              {{ item.name }}
            </LinkTo>
            <ChildNav v-if="item.path === active" :data="item.children" :title="item.name" :activePath="active"
              @close="hideChildMenu" />
          </li>
        </template>
      </ul>
    </div>
    <img class="nav-search" src="@/assets/nav/search.png" @click="go('/search')">
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import { ElMessage } from 'element-plus'

import LinkTo from './LinkTo'

import ChildNav from './ChildNav'

import aboutCarpoly from '@/assets/nav/about-carpoly.png'

import furniture1 from '@/assets/nav/furniture1.png'
import furniture2 from '@/assets/nav/furniture2.png'
import furniture3 from '@/assets/nav/furniture3.png'
import furniture4 from '@/assets/nav/furniture4.png'
import furniture5 from '@/assets/nav/furniture5.png'
import furniture6 from '@/assets/nav/furniture6.png'
import furniture7 from '@/assets/nav/furniture7.png'
import furniture8 from '@/assets/nav/furniture8.png'
import furniture9 from '@/assets/nav/furniture9.png'

import architectural1 from '@/assets/nav/architectural1.png'
import architectural2 from '@/assets/nav/architectural2.png'
import architectural3 from '@/assets/nav/architectural3.png'
import architectural4 from '@/assets/nav/architectural4.png'
import architectural5 from '@/assets/nav/architectural5.png'
import architectural6 from '@/assets/nav/architectural6.png'
import architectural7 from '@/assets/nav/architectural7.png'
import architectural8 from '@/assets/nav/architectural8.png'

import improvement1 from '@/assets/nav/improvement1.png'
import improvement2 from '@/assets/nav/improvement2.png'

import { getCategories } from '@/apis/index'

const route = useRoute()
const router = useRouter()

const active = ref('')

const level1Nav = ref([
  { name: 'Home', path: '/' },
  {
    name: 'About us',
    path: '/about',
    children: [
      {
        name: 'About carpoly', path: '/about/index', imgSrc: aboutCarpoly
      }
    ]
  },
  {
    name: 'Products',
    path: '/products',
    children: [
      {
        name: 'Furniture coating',
        path: '/products/furniture',
        children: []
      },
      {
        name: 'Architectural coating',
        path: '/products/architectural',
        children: []
      },
      {
        name: 'Home improvement',
        path: '/products/improvement',
        children: [
          {
            name: 'Interior Wall Emulsion Paint', path: '/products/improvement/product', imgSrc: improvement1
          },
          {
            name: 'Decoration Cases', path: '/products/improvement/case', imgSrc: improvement2
          }
        ]
      }
    ]
  },
  { name: 'Support', path: '/support' },
  { name: 'News', path: '/news' },
  { name: 'Contacts', path: '#contacts' }
])

const fetchCategories = (params) => {
  getCategories(params).then((resp) => {
    if (resp.code !== 1) {
      ElMessage({
        message: resp.msg,
        type: 'error',
        offset: 100
      })
      return
    }
    if (params.department_id === 2) {
      level1Nav.value[2].children[0].children = [
        ...resp.data.filter((item) => item.is_nav === 1).map((item, index) => ({ name: item.name, path: `/products/furniture/product?seriesId=${item.id}`, imgSrc: index === 0 ? furniture1 : index === 1 ? furniture2 : index === 2 ? furniture3 : index === 3 ? furniture4 : index === 4 ? furniture5 : index === 5 ? furniture6 : index === 6 ? furniture7 : '' })),
        {
          name: 'Solutions', path: '/products/furniture/index#solutions', imgSrc: furniture8
        },
        {
          name: 'Application & Scenario', path: '/products/furniture/space', imgSrc: furniture9
        }
      ]
    } else {
      level1Nav.value[2].children[1].children = [
        ...resp.data.filter((item) => item.is_nav === 1).map((item, index) => ({ name: item.name, path: `/products/architectural/product?seriesId=${item.id}`, imgSrc: index === 0 ? architectural1 : index === 1 ? architectural2 : index === 2 ? architectural3 : index === 3 ? architectural5 : index === 4 ? architectural4 : '' })),
        {
          name: 'Solutions', path: '/products/architectural/solution', imgSrc: architectural6
        },
        {
          name: 'Effect Display', path: '/products/architectural/display', imgSrc: architectural7
        },
        {
          name: 'Project Cases', path: '/products/architectural/case', imgSrc: architectural8
        }
      ]
    }
  })
    .catch((error) => console.log(error))
}

onBeforeMount(() => {
  fetchCategories({
    department_id: 2,
    type: 'product'
  })
  fetchCategories({
    department_id: 3,
    type: 'product'
  })
})

// 一级菜单 active 状态判断
const handleActiveLevel1Nav = (path) => {
  return path === '/' ? route.path === path ? 'active' : '' : route.path.includes(path) ? 'active' : ''
}

const handleStopWheel = (e) => {
  e.preventDefault()
}

const handleMouseover = (params) => {
  if (!params?.children || params.children.length === 0) return
  active.value = params.path
  if (['/about', '/products'].includes(params.path)) {
    window.addEventListener('wheel', handleStopWheel, {
      passive: false
    })
  }
}

const hideChildMenu = () => {
  active.value = ''
  window.removeEventListener('wheel', handleStopWheel)
}

const go = (path, query) => {
  router.push({
    path,
    query
  })
}

</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 79px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: #ffffff;

  &>.nav-content {
    width: 1080px;
    height: 100%;
    display: flex;
    align-items: center;

    & .nav-content-logo {
      width: 188px;
      height: 29px;
      margin-right: 224px;
    }

    &>.nav-content-menu {
      width: 670px;
      height: 100%;
      display: flex;
      justify-content: flex-start;

      & .nav-content-menu-item {
        width: 110px;
        height: 100%;

        & a {
          display: block;
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          color: #323232;
          line-height: 79px;
          position: relative;
        }

        & a:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 37.5px;
          width: 35px;
          height: 10px;
          background-color: #e73c3e;

          transform: scaleX(0);
          transition: transform 0.25s ease-in-out;
        }

        // & :deep(.box) {
        //   display: none;
        // }

        // & :deep(.shadow) {
        //   display: none;
        // }

      }

      & .nav-content-menu-item:hover {

        & a:after {
          transform: scaleX(1);
        }

        // & :deep(.box) {
        //   display: block;
        // }

        // & :deep(.shadow) {
        //   display: block;
        // }

      }

      & .nav-content-menu-item.active {
        & a:after {
          transform: scaleX(1);
        }
      }

    }

  }

  &>.nav-search {
    width: 27px;
    height: 28px;
    position: absolute;
    top: 50%;
    right: 62px;
    transform: translateY(-50%);
    cursor: pointer;
  }

}
</style>
