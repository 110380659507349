import { setupAnimateDirective } from './animate.js'

/**
 * 全局注册指令方法
 *
 * @export
 * @param {*} app
 */
export function setupGlobDirectives (app) {
  setupAnimateDirective(app)
}
