import request from '@/utils/request'

// 获取banner
export const getBanner = (data) =>
  request({
    url: '/kvImage/getList',
    method: 'POST',
    data
  })

// 获取视屏
export const getVideo = (data) =>
  request({
    url: '/video/getList',
    method: 'POST',
    data
  })

// 获取关于我们详情
export const getWelfare = (data) =>
  request({
    url: '/about/getWelfare',
    method: 'POST',
    data
  })

// 获取banner
export const getNewsList = (data) =>
  request({
    url: '/news/getList',
    method: 'POST',
    data
  })

export const getNewsOne = (data) =>
  request({
    url: '/news/getOne',
    method: 'POST',
    data
  })

// 提交联系方式
export const submitContact = (data) =>
  request({
    url: '/service/add',
    method: 'POST',
    data
  })

// 全家装 案例 风格列表
export const getStyleList = (data) =>
  request({
    url: '/configList/getStyleList',
    method: 'POST',
    data
  })

// 全家装 案例 风格案例列表
export const getStyleCaseList = (data) =>
  request({
    url: '/design/getList',
    method: 'POST',
    data
  })

// 获取家具产品分类、行业解决方案
export const getCategories = (data) =>
  request({
    url: '/department/getList',
    method: 'POST',
    data
  })

// 获取家具产品列表
export const getFurnitureList = (data) =>
  request({
    url: '/product/getFurnitureList',
    method: 'POST',
    data
  })

export const getProductDetail = (data) =>
  request({
    url: '/product/getOne',
    method: 'POST',
    data
  })

// 获取工艺空间分类
export const getSpaceTypeList = (data) =>
  request({
    url: '/configList/getSpaceTypeList',
    method: 'POST',
    data
  })

// 获取工艺空间产品列表
export const getSpaceProList = (data) =>
  request({
    url: '/product/getSpaceProList',
    method: 'POST',
    data
  })

// 获取解决方案以及子产品列表
export const getBuildList = (data) =>
  request({
    url: '/product/getBuildList',
    method: 'POST',
    data
  })

export const getSolutionProList = (data) =>
  request({
    url: '/product/getSolutionProList',
    method: 'POST',
    data
  })

export const getSolutionProWorkList = (data) =>
  request({
    url: '/product/getWorkList',
    method: 'POST',
    data
  })

export const getCaseList = (data) =>
  request({
    url: '/projectCase/getList',
    method: 'POST',
    data
  })

export const getCaseTypeList = (data) =>
  request({
    url: '/projectCase/getCaseTypeList',
    method: 'POST',
    data
  })

export const getCaseOne = (data) =>
  request({
    url: '/projectCase/getOne',
    method: 'POST',
    data
  })

export const getDisplay = (data) =>
  request({
    url: '/showProduct/getList',
    method: 'POST',
    data
  })

// 全局搜索
export const getSearchList = (data) =>
  request({
    url: '/index/getSearchList',
    method: 'POST',
    data
  })
