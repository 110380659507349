import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'

// css 动画效果
import 'animate.css'
// 全局样式引入
import '@/styles/index.scss'

import 'swiper/css'

import { setupGlobDirectives } from './directives/index'

// 创建实例
const app = createApp(App)

// 注册路由
app.use(router)
// 全局注册指令
setupGlobDirectives(app)

// 挂载饿了么组件库
app.use(ElementPlus)

// 挂载app
app.mount('#app')
