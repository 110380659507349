/**
 * 滚动到一定位置添加动画样式类
 */
const animateDirective = {
  mounted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      // 获取node 到视窗的高度 含滚动高度
      const { top } = el.getBoundingClientRect()
      // 获取窗口高度
      const h =
        document.documentElement.clientHeight || document.body.clientHeight
      // 判断是否滚动到位置
      if (top < h) {
        if (el.className.indexOf(binding.value) === -1) {
          el.className = binding.value + ' ' + el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window?.addEventListener('scroll', binding.addClass, true)
  },
  unmounted: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
}

/**
 * 注册指令
 *
 * @export
 * @param {*} app
 */
export function setupAnimateDirective (app) {
  app.directive('animate', animateDirective)
}
