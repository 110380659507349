<template>
  <div class="box">
    <div class="content">
      <div class="left-nav">
        <h1 class="left-nav-title">{{ props.title }}</h1>
        <ul class="left-nav-menu" v-if="showLeftMenu">
          <li class="left-nav-menu-item" :class="{ active: activeLeftNav === item.path }" v-for="item in leftNav"
            :key="item.path" @mouseenter="handleLeftNavMouseenter(item)">
            <LinkTo :to="item.path">
              {{ item.name }}
            </LinkTo>
          </li>

        </ul>
      </div>
      <ul class="right-nav" :style="{ width: `${rightNavWidth}px` }">
        <li :class="handleRightNavClass(item.path)" :style="{ backgroundImage: `url(${item.imgSrc})` }"
          v-for="item in rightNav " :key="item.path" @click="emit('close')">
          <LinkTo :to="item.path">
            <span>{{ item.name }}</span>
          </LinkTo>
        </li>
      </ul>
    </div>
  </div>
  <div class="shadow" @mouseenter="emit('close')"></div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch, defineEmits } from 'vue'

import { useRoute } from 'vue-router'

import LinkTo from './LinkTo'

const route = useRoute()

const emit = defineEmits(['close'])

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  data: {
    type: Array,
    default: () => ([])
  },
  activePath: {
    type: String,
    default: ''
  }
})

// 计算是否展示左侧菜单
const showLeftMenu = computed(() => {
  return ['Products'].includes(props.title)
})

// 左侧菜单数据
const leftNav = ref([])

// 激活的左侧菜单
const activeLeftNav = ref('')

// 右侧菜单数据
const rightNav = ref([])

// 右侧导航栏的宽度
const rightNavWidth = ref(585)

onMounted(() => {
  if (showLeftMenu.value) {
    leftNav.value = props?.data
    rightNav.value = props.data[0]?.children
    handleLeftNav(route.path)
  } else {
    leftNav.value = []
    rightNav.value = props?.data
  }
})

// 处理左侧菜单 active
const handleLeftNav = (path) => {
  if (path.includes('/products/furniture')) {
    activeLeftNav.value = '/products/furniture'
    rightNavWidth.value = 585
  } else if (path.includes('/products/architectural')) {
    activeLeftNav.value = '/products/architectural'
    rightNavWidth.value = 585
  } else if (path.includes('/products/improvement')) {
    activeLeftNav.value = '/products/improvement'
    rightNavWidth.value = 756
  }
  // 三级菜单 数据
  rightNav.value = props.data.find((item) => item.path === activeLeftNav.value)?.children
}

// 监听 一级active菜单
watch(() => props.activePath, (value) => {
  if (value === '/products') {
    route.path.includes('/products') ? handleLeftNav(route.path) : handleLeftNav('/products/furniture')
  } else {
    rightNav.value = props?.data
  }
}, {
  deep: true,
  immediate: true
})

// 左侧菜单hover事件
const handleLeftNavMouseenter = (params) => {
  activeLeftNav.value = params.path
  rightNav.value = params.children
  if (params.path === '/products/improvement') {
    rightNavWidth.value = 748
  } else {
    rightNavWidth.value = 585
  }
}

const handleRightNavClass = (path) => {
  return [
    'right-nav-item',
    route.fullPath === path ? 'active' : '',
    path.includes('/about') ? 'right-nav-about-item' : path.includes('/products/furniture') ? 'right-nav-furniture-item' : path.includes('/products/architectural') ? 'right-nav-architectural-item' : path.includes('/products/improvement') ? 'right-nav-improvement-item' : ''
  ]
}

</script>

<style lang="scss" scoped>
.box {
  width: 100vw;
  background: #FFFFFF;
  position: absolute;
  left: 0px;
  z-index: 1000;

  &>.content {
    width: 960px;
    margin: 0 auto;
    padding: 36px 0px;
    display: flex;

    &>.left-nav {
      width: 202px;

      &>.left-nav-title {
        font-weight: 400;
        font-size: 32px;
        color: #323232;
        padding-bottom: 32px;
      }

      &>.left-nav-menu {
        display: flex;
        flex-direction: column;

        &>.left-nav-menu-item {
          position: relative;

          & a {
            display: block;
            width: 202px;
            height: 38px;
            line-height: 38px;
            padding-left: 35px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 16px;
            color: #323232;
          }
        }

        &>.left-nav-menu-item::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background: linear-gradient(90deg, #031EB7, #1847BC, #3055BD);
          transform: scaleY(0);
          transition: transform 0.25s ease-in-out;
        }

        &>.left-nav-menu-item.active {
          background: #F5F5F5;
        }

        &>.left-nav-menu-item.active::after {
          transform: scaleY(1);
        }
      }
    }

    &>.right-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 28px;
      max-height: 521px;
      overflow-y: auto;
      overflow-x: hidden;

      &>.right-nav-item {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        height: 159px;
        margin-bottom: 22px;

        & a {
          display: block;
          width: 100%;
          height: 100%;
        }

        & span {
          display: block;
          width: 100%;
          height: 36px;
          background: #FFFFFF;
          opacity: 0.8;
          color: #323232;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          line-height: 36px;
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }

      &>.right-nav-item.active {
        & span {
          background: linear-gradient(90deg, #031EB7, #1847BC, #3055BD);
          color: #FFFFFF;
        }
      }

      &>.right-nav-item:hover {
        & span {
          background: linear-gradient(90deg, #031EB7, #1847BC, #3055BD);
          color: #FFFFFF;
        }
      }

      &>.right-nav-about-item {
        width: 287px;
        margin-bottom: 0px;
      }

      &>.right-nav-furniture-item:nth-child(1),
      &>.right-nav-furniture-item:nth-child(2),
      &>.right-nav-furniture-item:nth-child(3),
      &>.right-nav-furniture-item:nth-child(4) {
        width: 140px;
      }

      &>.right-nav-furniture-item:nth-child(5),
      &>.right-nav-furniture-item:nth-child(6),
      &>.right-nav-furniture-item:nth-child(7) {
        width: 186px;
      }

      &>.right-nav-furniture-item:nth-child(8),
      &>.right-nav-furniture-item:nth-child(9) {
        width: 283px;
        margin-bottom: 0px;
      }

      &>.right-nav-architectural-item:nth-child(1),
      &>.right-nav-architectural-item:nth-child(2) {
        width: 288px;
      }

      &>.right-nav-architectural-item:nth-child(n+3) {
        width: 186px;
      }

      &>.right-nav-architectural-item:nth-child(n+6) {
        margin-bottom: 0px;
      }

      &>.right-nav-improvement-item {
        width: 357px;
        margin-bottom: 0px;
      }
    }

  }
}

.shadow {
  position: fixed;
  top: 79px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 79px);
  background: rgba(0, 0, 0, .7);
  z-index: 100;
}
</style>
