<template>
  <component :is="type" v-bind="linkProps(props.to)">
    <slot />
  </component>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { isExternal } from '@/utils'

const props = defineProps({
  to: {
    type: String,
    required: true
  }
})

// 判断是否外部地址
const isExternalValue = computed(() => {
  return isExternal(props.to)
})

// 计算component 具体表现
const type = computed(() => {
  if (isExternalValue.value) {
    return 'a'
  }
  return 'router-link'
})

// 解析参数
const linkProps = (to) => {
  if (isExternalValue.value) {
    return {
      href: to,
      target: '_blank',
      rel: 'noopener'
    }
  }
  return {
    to: to
  }
}

</script>

<style lang="scss" scoped></style>
