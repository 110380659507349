<template>
  <div id="contacts" class="contact">
    <div class="contacts-box">
      <div class="contacts-box-left">
        <h2>Carpoly Chemical Group Co., Ltd.</h2>
        <div class="contacts-box-left-item">
          <span>Tel:</span>
          <span>+86-750-357 8544/8825 </span>
        </div>
        <div class="contacts-box-left-item">
          <span> Fax:</span>
          <span> +86-750-3578771</span>
        </div>
        <div class="contacts-box-left-item">
          <span>Email:</span>
          <span> bui@carpoly.com</span>
        </div>
        <div class="contacts-box-left-item">
          <span>Address:</span>
          <span> Jinxi lndustrial Zone, Tangxia Town, Pengjiang District, Jiangmen, Guangdong 529085 P.R.C.</span>
        </div>
        <div class="contacts-box-left-item">
          <span>Follow us:</span>
          <span>
            <img src="@/assets/footer/facebook.png" @click="handleImageClick(1)" alt="">
            <img src="@/assets/footer/instagram.png" @click="handleImageClick(2)" alt="">
            <img src="@/assets/footer/in.png" @click="handleImageClick(3)" alt="">
          </span>
        </div>
      </div>
      <div class="contacts-box-right">
        <h2>Contact Us</h2>
        <input class="contacts-box-right-input" type="text" v-model="form.name" placeholder="Your Name*">
        <input class="contacts-box-right-input" type="email" v-model="form.email" placeholder="Your Email*">
        <textarea class="contacts-box-right-textarea" type="textarea" v-model="form.content" placeholder="Your Message*"
          autocomplete="off" />
        <img class="contacts-box-right-send" src="@/assets/footer/send.png" @click="handleDebounceSubmit">
      </div>
    </div>

  </div>
  <div class="footer">
    <a href="https://beian.miit.gov.cn/" target="_blank">
      粤 ICP 备 05116880号
    </a>
    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44070302000406" target="_blank">
      粤公网安备 44070302000406号
    </a>
  </div>
</template>

<script setup>

import { ref, computed } from 'vue'

import _ from 'loadsh'

import { submitContact } from '@/apis/index'
import { ElMessage } from 'element-plus'

const form = ref({
  name: '',
  email: '',
  content: ''
})

const handleDebounceSubmit = computed(() => {
  return _.debounce(handleSubmit, 1000)
})

const handleSubmit = () => {
  if (!form.value.name) {
    ElMessage({
      message: 'Please fill in your name (within 10 characters)',
      type: 'error',
      offset: 100
    })
    return
  }
  if (!form.value.email) {
    ElMessage({
      message: 'Please fill in the correct email address',
      type: 'error',
      offset: 100
    })
    return
  }
  if (!form.value.content) {
    ElMessage({
      message: 'Please fill in your message (within 1,000 characters)',
      type: 'error',
      offset: 100
    })
    return
  }

  submitContact(form.value).then((resp) => {
    if (resp.code === 1) {
      form.value = {
        name: '',
        email: '',
        content: ''
      }
      ElMessage({
        message: 'Submission successful, our customer service representative will contact you as soon as possible!',
        type: 'success',
        offset: 100
      })
    } else {
      ElMessage({
        message: resp.msg,
        type: 'error',
        offset: 100
      })
    }
  })
    .catch((error) => console.log(error))
}

const handleImageClick = (type) => {
  switch (type) {
    case 1:
      window.open('https://www.facebook.com/profile.php?id=100057139012948 ')
      break
    case 2:
      window.open('https://www.instagram.com/carpolypaintoffical/')
      break
    case 3:
      window.open('https://www.linkedin.com/company/carpoly-chemical-group-co-ltd-/mycompany/')
      break
  }
}

</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  height: 494px;
  background: #2147B2;

  &>.contacts-box {
    width: 1080px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: 72px;
    box-sizing: border-box;

    &>div {
      display: flex;
      flex-direction: column;

      &>h2 {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #FFFFFF;
        padding-bottom: 36px;
      }

    }

    &>.contacts-box-left {
      width: 591px;
      margin-right: 110px;

      &>.contacts-box-left-item {
        display: flex;
        padding-bottom: 32px;

        &>span:nth-child(1) {
          width: 89px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          font-size: 20px;
          color: #FFFFFF;
          margin-right: 23px;
        }

        &>span:nth-child(2) {
          flex: 1;
          font-weight: 400;
          font-size: 20px;
          color: #FFFFFF;

          &>img {
            display: inline-block;
          }

          &>img:nth-child(2) {
            margin: 0px 32px;
          }
        }
      }

    }

    &>.contacts-box-right {
      width: 380px;
      position: relative;
      z-index: 1;

      &>input,
      textarea {
        width: 100%;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        outline: none;
        background: none;
        margin-bottom: 24px;
        font-weight: 300;
        font-size: 16px;
        color: #FFFFFF;
        text-indent: 18px;
      }

      &>input::placeholder {
        color: #FFFFFF;
        opacity: 0.8;
      }

      &>.contacts-box-right-input {
        height: 46px;
        line-height: 1;
      }

      &>.contacts-box-right-textarea {
        height: 141px;
        padding: 16px 18px;
        text-indent: 0px;
        margin: 0px;
      }

      &>.contacts-box-right-textarea::placeholder {
        color: #FFFFFF;
        opacity: 0.8;
      }

      &>.contacts-box-right-send {
        position: absolute;
        right: 11px;
        bottom: 86px;
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 154px;
  box-sizing: border-box;
  padding-top: 67px;

  display: flex;
  justify-content: center;

  &>a {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #1A1A1A;
    line-height: 22px;
    opacity: 0.5;
  }

  &>a:nth-child(1) {
    padding-right: 25px;
  }
}
</style>
